import React from "react";
import { Link } from 'react-router-dom';
import imgMenu from '../assets/img/img-menu.jpg';

const Sidebar = props => {
    const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
    return (
        <div className={sidebarClass}>
            <div className="sidebar-content-wrapper">
                <img alt="menu image" className="img-menu img-content" src={imgMenu} />
                <ul className="sidebar-content">
                    <li className="sidebar-medallium-link"><Link onClick={props.toggleSidebar} to="/">Medallium</Link></li>
                    <li><Link onClick={props.toggleSidebar} to="/qr-code">QR Code</Link></li>
                    <li><Link onClick={props.toggleSidebar} to="/baffle-board">Baffle Board</Link></li>
                    <li className="sidebar-attitudes-link"><Link onClick={props.toggleSidebar} to="/attitudes">Attitudes</Link></li>
                    <li><Link onClick={props.toggleSidebar} to="/password">Password</Link></li>
                    <li><Link onClick={props.toggleSidebar} to="/rare-special-soul">RARE Special Soul</Link></li>
                    <li><Link onClick={props.toggleSidebar} to="/food">Food</Link></li>
                    <li className="sidebar-policy-link"><Link onClick={props.toggleSidebar} to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link onClick={props.toggleSidebar} to="/changelog">Changelog</Link></li>
                </ul>
            </div>
        </div>
    );
};
export default Sidebar;