import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation  } from 'react-router-dom';

import Sidebar from "./pages/Sidebar";
import Medallium from './pages/YokaiDex.js';
import YokaiDetail from './pages/YokaiDetail.js';
import QRCode from './pages/QRCode.js';
import BaffleBoard from './pages/BaffleBoard.js';
import Attitudes from './pages/Attitudes.js';
import Password from './pages/Password.js';
import RARESpecialSoul from './pages/RARESpecialSoul.js';
import Food from './pages/Food.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import Changelog from './pages/Changelog.js';
import './App.css';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleViewSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const bgSidebarClass = sidebarOpen ? "bg-sidebar" : "bg-sidebar hidden";

  return (
    <div className="App">
      <Sidebar 
        isOpen={sidebarOpen}
        toggleSidebar={handleViewSidebar}
      />
      <div className={bgSidebarClass} onClick={handleViewSidebar}></div>
      <Routes>
        <Route path="/" element={<Medallium title="Medallium" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/yokai-information" element={<YokaiDetail isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/qr-code" element={<QRCode title="QR Code" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/baffle-board" element={<BaffleBoard title="Baffle Board" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/attitudes" element={<Attitudes title="Attitudes" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/password" element={<Password title="Password" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/rare-special-soul" element={<RARESpecialSoul title="RARE Special Soul" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/food" element={<Food title="Food" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy title="Privacy Policy" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
        <Route path="/changelog" element={<Changelog title="Changelog" isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} />} />
      </Routes>
    </div>
  );
}

export default App;
