import React, { useEffect, useState } from 'react';
import '../assets/css/Changelog.css';
import iconNavbar from '../assets/img/icon-navbar.png';

const Changelog = props => {
    useEffect(() => {
        document.title = props.title;
    }, []);
    
    return(
        <div id="attitudes" className="content-wrapper">
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#AA90B4", }}>
                        <div className="header-title-wrapper">
                            <h1 className="header-title">Changelog</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="changelog-content">
                <div className="container">
                    <p className="changelog-content-title">v0.1.0 release</p>
                    <p>- Medallium</p>
                    <p>- Baffle Board</p>
                    <p>- Attitude</p>
                    <p>- RARE Special Soul</p>
                </div>
            </div>
        </div>
    )
}

export default Changelog;