import React, { useEffect } from 'react';

import '../assets/css/QRCode.css';
import iconNavbar from '../assets/img/icon-navbar.png';

const QRCode = props => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    return(
        <div id="qr-code" className="content-wrapper">
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#FFC648", }}>
                        <div className="header-title-wrapper">
                            <h1 className="header-title">QR Code</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="qr-code-content">
                <div className="container">
                    <p>Coming Soon!</p>
                </div>
            </div>
        </div>
    )
}

export default QRCode;