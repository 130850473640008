import React, { useEffect } from 'react';

import '../assets/css/Attitudes.css';
import iconNavbar from '../assets/img/icon-navbar.png';

const Attitudes = props => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    return(
        <div id="attitudes" className="content-wrapper">
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#AA90B4", }}>
                        <div className="header-title-wrapper">
                            <h1 className="header-title">Attitudes</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="attitudes-content">
                <div className="container">
                    <table cellSpacing={0} className="table-attitudes">
                        <thead>
                            <tr>
                                <th>Attitude</th>
                                <th>Stat(s) Gained</th>
                                <th>Battle Action Preference</th>
                                <th>Associated Book</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Grouchy</td>
                                <td>HP/STR</td>
                                <td>Attack</td>
                                <td>Think Karate</td>
                            </tr>

                            <tr>
                                <td>Rough</td>
                                <td>STR+</td>
                                <td>Attack</td>
                                <td>Use Karate</td>
                            </tr>

                            <tr>
                                <td>Logical</td>
                                <td>SPR/SPD</td>
                                <td>Technique</td>
                                <td>Skill Compendium</td>
                            </tr>

                            <tr>
                                <td>Brainy</td>
                                <td>SPR+</td>
                                <td>Technique</td>
                                <td>Skill Encyclopedia</td>
                            </tr>

                            <tr>
                                <td>Twisted</td>
                                <td>STR/SPD</td>
                                <td>Insprit (Enemy)</td>
                                <td>The Pest's Quest</td>
                            </tr>

                            <tr>
                                <td>Cruel</td>
                                <td>SPD+</td>
                                <td>Insprit (Enemy)</td>
                                <td>The Perfect Pest</td>
                            </tr>

                            <tr>
                                <td>Helpful</td>
                                <td>HP/SPD</td>
                                <td>Insprit (Ally)</td>
                                <td>Support Life #7</td>
                            </tr>

                            <tr>
                                <td>Devoted</td>
                                <td>STR/DEF</td>
                                <td>Insprit (Ally)</td>
                                <td>Support Special</td>
                            </tr>

                            <tr>
                                <td>Gentle</td>
                                <td>HP/SPR</td>
                                <td>Technique (Ally)</td>
                                <td>Li'l Angel Heals</td>
                            </tr>

                            <tr>
                                <td>Tender</td>
                                <td>HP+</td>
                                <td>Technique (Ally)</td>
                                <td>Bye, Li'l Angel</td>
                            </tr>

                            <tr>
                                <td>Careful</td>
                                <td>DEF/SPR</td>
                                <td>Guard</td>
                                <td>Get Guarding</td>
                            </tr>

                            <tr>
                                <td>Calm</td>
                                <td>DEF+</td>
                                <td>Guard</td>
                                <td>Guard Gloriously</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Attitudes;