import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../assets/css/BaffleBoard.css';
import iconNavbar from '../assets/img/icon-navbar.png';

const BaffleBoard = props => {
    const [Baffleboards2, setBaffleboards2] = useState([]);
    const [Baffleboards3, setBaffleboards3] = useState([]);
    const [Gateofwhimsy, setGateofwhimsy] = useState([]);

    useEffect(() => {
        fetch('./jsons/baffleboarddb.json')
        .then(res => res.json())
        .then(Baffleboards3 => {
            setBaffleboards3(Baffleboards3.baffleboards3);
        });

        fetch('./jsons/baffleboarddb.json')
        .then(res => res.json())
        .then(Baffleboards2 => {
            setBaffleboards2(Baffleboards2.baffleboards2);
        })

        fetch('./jsons/baffleboarddb.json')
        .then(res => res.json())
        .then(Gateofwhimsy => {
            setGateofwhimsy(Gateofwhimsy.gateofwhimsy);
        })

        document.title = props.title;
    }, []);

    return(
        <div id="baffle-board" className="content-wrapper">
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#6DAA73", }}>
                        <div className="header-title-wrapper">
                            <h1 className="header-title">Baffle Board</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="baffle-board-content">
                <div className="container">
                    <div className="baffle-board">
                        <h2 className="baffle-board-content-title">Yo-kai Watch 3</h2>
                        <div className="table-responsive">
                            <table cellSpacing={0} className="table-baffle-board table-baffle-board-1">
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>Clues</th>
                                        <th>Solution</th>
                                        <th>Effect</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    (
                                        Baffleboards3.map((baffleboard3) => {
                                            return (
                                                <tr key={baffleboard3.solution}>
                                                    <td>{baffleboard3.location}</td>
                                                    <td>
                                                        <p>1. {baffleboard3.clues[0]}</p>
                                                        <p>2. {baffleboard3.clues[1]}</p>
                                                        <p>3. {baffleboard3.clues[2]}</p>
                                                    </td>
                                                    <td className="baffle-board-solution">
                                                        <Link to="/yokai-information" state={{ yokainame: baffleboard3.solution, bgheader: baffleboard3.bgcolor, }}>
                                                            {baffleboard3.solution}
                                                        </Link>
                                                    </td>
                                                    <td>{baffleboard3.effect}</td>
                                                </tr>
                                            )
                                        })
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="baffle-board">
                        <h2 className="baffle-board-content-title">Yo-kai Watch 2</h2>
                        <div className="table-responsive">
                            <table cellSpacing={0} className="table-baffle-board table-baffle-board-2">
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>Clues</th>
                                        <th>Solution</th>
                                        <th>Effect</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (
                                            Baffleboards2.map((baffleboard2) => {
                                                return (
                                                    <tr key={baffleboard2.solution}>
                                                        <td>{baffleboard2.location}</td>
                                                        <td>
                                                            <p>1. {baffleboard2.clues[0]}</p>
                                                            <p>2. {baffleboard2.clues[1]}</p>
                                                            <p>3. {baffleboard2.clues[2]}</p>
                                                        </td>
                                                        <td className="baffle-board-solution">
                                                            <Link to="/yokai-information" state={{ yokainame: baffleboard2.solution, bgheader: baffleboard2.bgcolor, }}>
                                                                {baffleboard2.solution}
                                                            </Link>
                                                        </td>
                                                        <td>{baffleboard2.effect}</td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="baffle-board">
                        <h2 className="baffle-board-content-title">Gate of Whimsy: Quiz Room Baffle Boards</h2>
                        <div className="table-responsive">
                            <table cellSpacing={0} className="table-baffle-board table-baffle-board-3">
                                <thead>
                                    <tr>
                                        <th>Clues</th>
                                        <th>Solution</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (
                                            Gateofwhimsy.map((gateofwhimsy) => {
                                                return (
                                                    <tr key={gateofwhimsy.solution}>
                                                        <td>
                                                            <p>1. {gateofwhimsy.clues[0]}</p>
                                                            <p>2. {gateofwhimsy.clues[1]}</p>
                                                            <p>3. {gateofwhimsy.clues[2]}</p>
                                                        </td>
                                                        <td className="baffle-board-solution">
                                                            <Link to="/yokai-information" state={{ yokainame: gateofwhimsy.solution, bgheader: gateofwhimsy.bgcolor, }}>
                                                            {gateofwhimsy.solution}
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaffleBoard;