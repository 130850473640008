import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import iconSearch from '../assets/img/icon-search.png';
import iconNavbar from '../assets/img/icon-navbar.png';

const Medallium = props => {
    const [yokais, setYokais] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = yokais.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(yokais)
        }
    }

    useEffect(() => {
        fetch('./jsons/yokaidb.json')
        .then(res => res.json())
        .then(yokais => {
            setYokais(yokais);
        });
        document.title = props.title;
    }, []);

    return (
        <div className="medallium">
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#E85A3D", }}>
                        <div className="header-title-wrapper">
                            <h1 className="header-title">Medallium</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                        <div className="yokai-filter-wrapper">
                            <img alt="search" className="icon-search" src={iconSearch} />
                            <input 
                                onChange={(e) => searchItems(e.target.value)}
                                className="yokai-filter"
                                placeholder="Search..."
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="medallium-list">
                <div className="container">
                    {
                    searchInput.length > 1 ? (
                        filteredResults.map((yokai) => {
                            return (
                                <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                    <Link className="yokai-content" to="yokai-information" state={{ yokainame: yokai.name, bgheader: yokai.bgcolor, }}>
                                        <span className="yokai-left-wrapper">
                                            {
                                                yokai.img.map((data) => {
                                                    return (
                                                        <span key={yokai.key} className="img-yokai-wrapper">
                                                            <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                        </span>
                                                    )
                                                })
                                            }
                                            <h3 className="yokai-name">{yokai.name}</h3>
                                        </span>
                                        {
                                            yokai.img.map((data) => {
                                                return (
                                                    <span key={yokai.key} className="yokai-right-wrapper">
                                                        <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                        <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                    </span>
                                                )
                                            })
                                        }
                                        <span className="bg-color"></span>
                                    </Link>
                                </li>
                            )
                        })
                    ) : (
                        yokais.map((yokai) => {
                            return (
                                <li key={yokai.key} style={{ backgroundColor: yokai.bgcolor }}>
                                    <Link className="yokai-content" to="yokai-information" state={{ yokainame: yokai.name, bgheader: yokai.bgcolor, }}>
                                        <span className="yokai-left-wrapper">
                                            {
                                                yokai.img.map((data) => {
                                                    return (
                                                        <span key={yokai.key} className="img-yokai-wrapper">
                                                            <img className="img-yokai" alt={yokai.name} src={data.yokaiimg} />
                                                        </span>
                                                    )
                                                })
                                            }
                                            <h3 className="yokai-name">{yokai.name}</h3>
                                        </span>
                                        {
                                            yokai.img.map((data) => {
                                                return (
                                                    <span key={yokai.key} className="yokai-right-wrapper">
                                                        <img className="img-rank" alt={yokai.rank[0]} src={data.rankimg} />
                                                        <img className="img-trible" alt={yokai.tribe} src={data.tribeimg} />
                                                    </span>
                                                )
                                            })
                                        }
                                        <span className="bg-color"></span>
                                    </Link>
                                </li>
                            )
                        })
                    )}
                </div>
            </ul>
        </div>
    )
}

export default Medallium;