import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../assets/css/Food.css';
import iconNavbar from '../assets/img/icon-navbar.png';

const Food = props => {
    useEffect(() => {
        document.title = props.title;
    }, []);
    
    return(
        <div id="food" className="content-wrapper">
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#FBA6CC", }}>
                        <div className="header-title-wrapper">
                            <h1 className="header-title">Food</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="food-content">
                <div className="container">
                    <p>Coming Soon!</p>
                </div>
            </div>
        </div>
    )
}

export default Food;