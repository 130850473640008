import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../assets/css/RareSpecialSoul.css';
import iconNavbar from '../assets/img/icon-navbar.png';
import Bubble_Beth_Soul from '../assets/img/specialsoul/Bubble_Beth_Soul.png'
import Charge_Soul from '../assets/img/specialsoul/Charge_Soul.png'
import El_Gutso_Grande_Sl from '../assets/img/specialsoul/El_Gutso_Grande_Sl.png'
import Eyesoar_Soul from '../assets/img/specialsoul/Eyesoar_Soul.png'
import Hairum_Scarum_Sl from '../assets/img/specialsoul/Hairum_Scarum_Sl.png'
import Purification_Soul from '../assets/img/specialsoul/Purification_Soul.png'
import Snidewinder_Soul from '../assets/img/specialsoul/Snidewinder_Soul.png'
import Spiky_Soul from '../assets/img/specialsoul/Spiky_Soul.png'
import Statiking_Soul from '../assets/img/specialsoul/Statiking_Soul.png'
import Steaking_Soul from '../assets/img/specialsoul/Steaking_Soul.png'
import Whirlweasel_Soul from '../assets/img/specialsoul/Whirlweasel_Soul.png'

const RARESpecialSoul = props => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    return(
        <div id="rare-special-soul" className="content-wrapper">
            <div className="header-wrapper">
                <div className="container">
                    <div className="header-content" style={{ backgroundColor: "#7A9BBF", }}>
                        <div className="header-title-wrapper">
                            <h1 className="header-title">RARE Special Soul</h1>
                            <span onClick={props.toggleSidebar} className="menu-toggle"><img alt="navbar" className="icon-navbar" src={iconNavbar} /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rare-special-soul-content">
                <div className="container">
                    <div className="rare-special-soul-header">
                        <span>Soul</span>
                        <span></span>
                        <span>Fused with</span>
                        <span></span>
                        <span>Result</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Rawry", bgheader: "#AA90B4", }}>
                                <img alt="Rawry Soul" className="img-rare-special-soul" src={Hairum_Scarum_Sl} />
                                <span className="rare-special-soul-title">Rawry Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Snitchin", bgheader: "#7A9BBF", }}>
                                <img alt="Snitchin Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Snitchin Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">=</span>
                        <span>
                            <img alt="Spiky Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Spiky Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>All Yo-kai take more damage from Inspirits.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Whirlweasel", bgheader: "#FBA6CC", }}>
                                <img alt="Whirlweasel Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Whirlweasel Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "El Gutso", bgheader: "#E85A3D", }}>
                                <img alt="El Gutso Grande Sl." className="img-rare-special-soul" src={El_Gutso_Grande_Sl} />
                                <span className="rare-special-soul-title">El Gutso Grande Sl.</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Slicer Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Slicer Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Ignores Charms affecting target Yo-kai if attacking.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Snidewinder", bgheader: "#68E7EC", }}>
                                <img alt="Whirlweasel Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Snidewinder Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Sigh-Durr", bgheader: "#E85A3D", }}>
                                <img alt="Sigh-Durr Soul" className="img-rare-special-soul" src={El_Gutso_Grande_Sl} />
                                <span className="rare-special-soul-title">Sigh-Durr Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Combo Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Combo Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Can make combo attacks, even linked in a column.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Swelterrier", bgheader: "#FBA6CC", }}>
                                <img alt="Swelterrier Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Swelterrier Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Steaking", bgheader: "#FF8041", }}>
                                <img alt="Steaking Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Steaking Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Scorching Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Scorching Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Incinerate.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Faux Kappa", bgheader: "#FBA6CC", }}>
                                <img alt="Faux Kappa Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Faux Kappa Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Bubble Beth", bgheader: "#6DAA73", }}>
                                <img alt="Bubble Beth Soul" className="img-rare-special-soul" src={Bubble_Beth_Soul} />
                                <span className="rare-special-soul-title">Bubble Beth Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Soaking Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Soaking Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Waterfall.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Statiking", bgheader: "#FFC648", }}>
                                <img alt="Statiking Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Statiking Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Papa Bolt", bgheader: "#6DAA73", }}>
                                <img alt="Papa Bolt Soul" className="img-rare-special-soul" src={Bubble_Beth_Soul} />
                                <span className="rare-special-soul-title">Papa Bolt Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Sparking Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Sparking Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Voltage.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Eyesoar", bgheader: "#7A9BBF", }}>
                                <img alt="Eyesoar Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Eyesoar Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Hairum Scarum", bgheader: "#AA90B4", }}>
                                <img alt="Hairum Scarum Sl." className="img-rare-special-soul" src={Hairum_Scarum_Sl} />
                                <span className="rare-special-soul-title">Hairum Scarum Sl.</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Spacedust Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Spacedust Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Meteor.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Blizzaria", bgheader: "#FBA6CC", }}>
                                <img alt="Blizzaria Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Blizzaria Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Chilhuahua", bgheader: "#FBA6CC", }}>
                                <img alt="Chilhuahua Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Chilhuahua Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Subzero Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Subzero Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Blizzard.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Smogmella", bgheader: "#FFC648", }}>
                                <img alt="Smogmella Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Smogmella Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Tenguriginal", bgheader: "#FBA6CC", }}>
                                <img alt="Tenguriginal Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Tenguriginal Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Spin Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Spin Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Sets Technique to Storm.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Nurse Tongus", bgheader: "#6DAA73", }}>
                                <img alt="Nurse Tongus Soul" className="img-rare-special-soul" src={Bubble_Beth_Soul} />
                                <span className="rare-special-soul-title">Nurse Tongus Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Washogun", bgheader: "#E85A3D", }}>
                                <img alt="Washogun Soul" className="img-rare-special-soul" src={El_Gutso_Grande_Sl} />
                                <span className="rare-special-soul-title">Washogun Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Purification Soul" className="img-rare-special-soul" src={Purification_Soul} />
                            <span className="rare-special-soul-title">Purification Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Yo-kai may also purify an ally when healing.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Goruma", bgheader: "#FF8041", }}>
                                <img alt="Goruma Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Goruma Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Casanono", bgheader: "#FFC648", }}>
                                <img alt="Casanono Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Casanono Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Purification Soul" className="img-rare-special-soul" src={Purification_Soul} />
                            <span className="rare-special-soul-title">Purification Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Immune to critical damage.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Impass", bgheader: "#FF8041", }}>
                                <img alt="Impass Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Impass Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Robonyan", bgheader: "#FF8041", }}>
                                <img alt="Robonyan Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Robonyan Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Shielding Soul" className="img-rare-special-soul" src={Purification_Soul} />
                            <span className="rare-special-soul-title">Shielding Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Guard automatically after moving.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Chummer", bgheader: "#68E7EC", }}>
                                <img alt="Chummer Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Chummer Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Castelius Max", bgheader: "#FF8041", }}>
                                <img alt="Castelius Max Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Castelius Max Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Peerless Soul" className="img-rare-special-soul" src={Purification_Soul} />
                            <span className="rare-special-soul-title">Peerless Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Nullifies one Soultimate Move or attack.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Corptain", bgheader: "#E85A3D", }}>
                                <img alt="Corptain Soul" className="img-rare-special-soul" src={El_Gutso_Grande_Sl} />
                                <span className="rare-special-soul-title">Corptain Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Nird", bgheader: "#7A9BBF", }}>
                                <img alt="Nird Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Nird Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Sinister Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Sinister Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>All healing Techniques will be weaker.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Statikid", bgheader: "#FFC648", }}>
                                <img alt="Statikid Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Statikid Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Robonoko", bgheader: "#68E7EC", }}>
                                <img alt="Robonoko Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Robonoko Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Charge Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Charge Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Your Soul Meter fills as you move.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Dimmy", bgheader: "#7A9BBF", }}>
                                <img alt="Dimmy Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Dimmy Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Blandon", bgheader: "#7A9BBF", }}>
                                <img alt="Blandon Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Blandon Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Stealth Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Stealth Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Will not become target of attacks.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Greesel", bgheader: "#7A9BBF", }}>
                                <img alt="Greesel Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Greesel Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Allnyta", bgheader: "#7A9BBF", }}>
                                <img alt="Allnyta Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Allnyta Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Supernatural Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Supernatural Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Own Inspirits will always succeed.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Cupistol", bgheader: "#FFC648", }}>
                                <img alt="Cupistol Soul" className="img-rare-special-soul" src={Statiking_Soul} />
                                <span className="rare-special-soul-title">Cupistol Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Pinkipoo", bgheader: "#FBA6CC", }}>
                                <img alt="Pinkipoo Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Pinkipoo Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Superstar Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Superstar Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Improves chances of befriending an enemy.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Afronaut", bgheader: "#6DAA73", }}>
                                <img alt="Afronaut Soul" className="img-rare-special-soul" src={Bubble_Beth_Soul} />
                                <span className="rare-special-soul-title">Afronaut Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Slax", bgheader: "#AA90B4", }}>
                                <img alt="Pinkipoo Soul" className="img-rare-special-soul" src={Hairum_Scarum_Sl} />
                                <span className="rare-special-soul-title">Slax Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Weightless Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Weightless Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Ignores negative floor effects when moving.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Pandanoko", bgheader: "#68E7EC", }}>
                                <img alt="Pandanoko Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Pandanoko Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Starry Noko", bgheader: "#68E7EC", }}>
                                <img alt="Pinkipoo Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">Starry Noko Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Summoner's Soul" className="img-rare-special-soul" src={Charge_Soul} />
                            <span className="rare-special-soul-title">Summoner's Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Will make Wisps appear more often.</span>
                    </div>
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "T-Wrecks", bgheader: "#68E7EC", }}>
                                <img alt="T-Wrecks Soul" className="img-rare-special-soul" src={Snidewinder_Soul} />
                                <span className="rare-special-soul-title">T-Wrecks Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Urnfulfilled Noko", bgheader: "#68E7EC", }}>
                                <img alt="Urnfulfilled Soul" className="img-rare-special-soul" src={Steaking_Soul} />
                                <span className="rare-special-soul-title">Urnfulfilled Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Blowaway Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Blowaway Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Knocks back an enemy by one tile when attacking.</span>
                    </div>
                    
                    <div className="rare-special-soul">
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Rubeus J", bgheader: "#FBA6CC", }}>
                                <img alt="Rubeus J Soul" className="img-rare-special-soul" src={Whirlweasel_Soul} />
                                <span className="rare-special-soul-title">Rubeus J Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-plus">+</span>
                        <span>
                            <Link to="/yokai-information" state={{ yokainame: "Ed Zoff Noko", bgheader: "#7A9BBF", }}>
                                <img alt="Urnfulfilled Soul" className="img-rare-special-soul" src={Eyesoar_Soul} />
                                <span className="rare-special-soul-title">Ed Zoff Soul</span>
                            </Link>
                        </span>
                        <span className="rare-special-soul-equal">+</span>
                        <span>
                            <img alt="Red Rush Soul" className="img-rare-special-soul" src={Spiky_Soul} />
                            <span className="rare-special-soul-title">Red Rush Soul</span>
                        </span>
                    </div>
                    <div className="rare-special-soul-desc">
                        <span>Can act again immediately after defeating an enemy.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RARESpecialSoul;